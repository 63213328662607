import Head from "components/Head"
import { Hero } from "components/Landing2/Hero"
import Stats from "components/Landing2/Stats"
import { SecondaryFeatures } from "components/Landing2/Features"
import FeatureTwo from "components/Landing2/FeatureTwo"

import { Header } from "components/Landing2/Header"
import CTA from "components/Landing2/CTA"
import TickerComponent from "components/Landing2/Ticker"
import Testimonials from "components/Landing2/SingleTestimonial"
import Footer from "components/Landing2/Footer"
import Overview from "components/Landing2/Referrals/Overview"

export default function IndexPage() {
  return (
    <div className="overflow-hidden">
      <Head
        share_img="https://paragraph.xyz/share/share_img.jpg"
        type="website"
        fullUrl="https://paragraph.xyz"
      />
      {/*
      <div className="fixed w-full z-50 backdrop-blur-lg bg-black bg-opacity-60">
        <Navbar />
      </div>
        */}

      <Header />
      <main>
        <Hero />
        <TickerComponent />
        <Stats />
        <SecondaryFeatures />
        <FeatureTwo />
        <Overview showMoreButton />
        <Testimonials />
        <CTA />
        <Footer />
      </main>
    </div>
  )
}
