import { MagnifyingGlassIcon, PaintBrushIcon } from "@heroicons/react/24/solid"
import { useState } from "react"
import { BsCollectionFill, BsPersonBadge } from "react-icons/bs"
import { IoAnalytics } from "react-icons/io5"
import { MdMarkEmailRead, MdStorage } from "react-icons/md"
import { RiTeamFill } from "react-icons/ri"
import { TbRosetteDiscountCheck } from "react-icons/tb"
import Image from "next/image"

const features = [
  {
    name: "AI-powered writing.",
    description:
      "Make writers block a thing of the past. Our AI-powered writing assistant helps you write better, faster.",
    icon: MagnifyingGlassIcon,
  },
  {
    name: "Email workflows and automations.",
    description:
      "Reach your members at the right time, and follow up automatically, with advanced workflows and automation.",
    icon: MdMarkEmailRead,
  },
  {
    name: "Ownable memberships.",
    description:
      "Subscribers get a digital collectible, indicating they're a part of your community. Provide different perks to different tiers.",
    icon: BsPersonBadge,
  },
  {
    name: "Collectible content.",
    description:
      "Unlock new streams of revenue by turning your content into collectibles.",
    icon: BsCollectionFill,
  },
  {
    name: "Permanent & composable storage.",
    description:
      "Store once, use anywhere. Your content is stored on Arweave and can be accessed from any platform.",
    icon: MdStorage,
  },
  {
    name: "Advanced analytics.",
    description:
      "From time-on-page to email open rates, analyze everything you need to know about your audience.",
    icon: IoAnalytics,
  },

  {
    name: "Teams & roles.",
    description:
      "Invite your team to help you manage your community. Create custom roles and permissions to suit your needs.",
    icon: RiTeamFill,
  },

  {
    name: "Theming & customization.",
    description:
      "Make your community your own. Customize your community & newsletter with your own branding, colors, and domain name.",
    icon: PaintBrushIcon,
  },

  {
    name: "Trials & discounts.",
    description:
      "Offer free trials and discounts to your members. Create custom discount codes to increase conversions.",
    icon: TbRosetteDiscountCheck,
  },
]

export default function Example() {
  const [hover, setHover] = useState()

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-600 uppercase tracking-widest">
            create without constraints
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Creativity powered by digital memberships
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Don't just gain subscribers - gain a community. Create digital
            memberships that are owned by your audience, and can be used across
            the web.
          </p>
        </div>
      </div>
      <div className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <Image
            src="/landing2/w3a.png"
            alt="App screenshot"
            className="mb-[-42%] rounded-xl shadow-2xl ring-1 ring-gray-900/10 p-4"
            width={2432}
            height={1442}
            sizes="(min-width: 1024px) 1184px, 100vw"
          />
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>
      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div
              key={feature.name}
              className="relative pl-9 hover:bg-gray-50 p-3 rounded-xl "
            >
              <dt className="inline font-semibold text-gray-900">
                <feature.icon
                  className="absolute left-3 top-4 h-5 w-5 text-blue-600"
                  aria-hidden="true"
                />
                {feature.name}
              </dt>{" "}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
