"use client"

import { useId, useState } from "react"
import Image, { type ImageProps } from "next/image"
import { Tab } from "@headlessui/react"
import clsx from "clsx"

import { Container } from "components/Landing2/Container"
import monetization from "public/landing2/earn.jpg"
import grow from "public/landing2/two_iphones_xmtp_email.png"
import db from "public/dashboard_dark_2.png"

interface Feature {
  name: React.ReactNode
  summary: string
  description: string
  image: ImageProps["src"] | null
  icon: React.ComponentType
  width: number
  classNames?: string
}

const features: Array<Feature> = [
  {
    name: "Create",
    summary: "Content creation just got easier",
    description:
      "A world-class editor means your content looks great anywhere. And, with AI to supercharge your writing, you'll never have writers block again.",
    image: db,
    width: 1200,
    classNames: "shadow-2xl shadow-slate-900/50 ring-slate-500/10",

    icon: function InventoryIcon() {
      return (
        <>
          <path
            opacity=".5"
            d="M8 17a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
          <path
            opacity=".3"
            d="M8 24a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
          <path
            d="M8 10a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z"
            fill="#fff"
          />
        </>
      )
    },
  },
  {
    name: "Grow",
    summary: "Turn your subscribers into patrons",
    description:
      "Send updates straight to your audience's inbox (or crypto wallet), and grow your following using advanced newsletter automation and social integrations.",

    width: 650,
    image: grow,

    icon: function ContactsIcon() {
      return (
        <>
          <path
            opacity=".5"
            d="M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75.357.421.993.419 1.384.028.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z"
            fill="#fff"
          />
          <path
            d="M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5 1.343 3.5 3 3.5 3-1.567 3-3.5Z"
            fill="#fff"
          />
        </>
      )
    },
  },
  {
    name: "Monetize",
    summary: "Earn a global stream of revenue",
    description:
      "From collectible content to recurring subscriptions, monetize across your entire audience and get paid in both cash and crypto.",
    image: monetization,
    width: 700,

    icon: function ReportingIcon() {
      const id = useId()
      return (
        <>
          <defs>
            <linearGradient
              id={id}
              x1="11.5"
              y1={18}
              x2={36}
              y2="15.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset=".194" stopColor="#fff" />
              <stop offset={1} stopColor="#6692F1" />
            </linearGradient>
          </defs>
          <path
            d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5"
            stroke={`url(#${id})`}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    },
  },
]

function Feature({
  feature,
  isActive,
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div"> & {
  feature: Feature
  isActive: boolean
}) {
  return (
    <div
      className={clsx(
        className,
        !isActive && "opacity-60 hover:opacity-100 cursor-pointer"
      )}
      {...props}
    >
      <div
        className={clsx(
          "w-9 rounded-lg",
          isActive ? "bg-blue-600" : "bg-slate-500"
        )}
      >
        <svg aria-hidden="true" className="h-9 w-9" fill="none">
          <feature.icon />
        </svg>
      </div>
      <h3
        className={clsx(
          "mt-6 text-sm font-medium !ring-0 !border-0",
          isActive ? "text-blue-600" : "text-slate-600"
        )}
      >
        {feature.name}
      </h3>
      <p className={clsx("mt-2 font-medium text-xl text-slate-900")}>
        {feature.summary}
      </p>
      <p className="mt-4 text-md leading-7 text-slate-600">
        {feature.description}
      </p>
    </div>
  )
}

function FeaturesMobile() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.summary}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" />
            <div className="relative mx-auto [20.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
              {feature.image && (
                <Image
                  className={clsx("w-full", feature.classNames)}
                  src={feature.image}
                  alt=""
                  sizes="52.75rem"
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

function sum(index: number): number {
  let sum = 0

  for (let i = 0; i < index; i++) {
    sum += features[i].width
  }

  return sum
}

function FeaturesDesktop() {
  const [selectedIndex, setSelectedIndex] = useState(0)
  return (
    <Tab.Group
      as="div"
      className="hidden lg:mt-20 lg:block"
      onChange={setSelectedIndex}
      selectedIndex={selectedIndex}
    >
      <>
        <Tab.List className="grid grid-cols-3 gap-x-8">
          {features.map((feature, featureIndex) => (
            <Feature
              key={feature.summary}
              onClick={() => setSelectedIndex(featureIndex)}
              feature={{
                ...feature,
                name: (
                  <Tab className="outline-none">
                    <span className="absolute inset-0" />
                    {feature.name}
                  </Tab>
                ),
              }}
              isActive={featureIndex === selectedIndex}
              className="relative"
            />
          ))}
        </Tab.List>
        <Tab.Panels className="relative mt-20 overflow-hidden rounded-4xl bg-slate-200 px-14 py-16 xl:px-16">
          <div className="-mx-5 flex">
            {features.map((feature, featureIndex) => (
              <Tab.Panel
                static
                key={feature.summary}
                className={clsx(
                  "px-5 transition duration-500 ease-in-out ui-not-focus-visible:outline-none",
                  featureIndex !== selectedIndex && "opacity-60"
                )}
                style={{
                  transform: `translateX(-${sum(selectedIndex)}px)`,
                }}
                aria-hidden={featureIndex !== selectedIndex}
              >
                {feature.image && (
                  <div
                    className={clsx(
                      `overflow-hidden rounded-xl shadow-slate-900/5 ring-slate-500/10`,
                      feature.classNames
                    )}
                    style={{ width: feature.width }}
                    onClick={() => setSelectedIndex(featureIndex)}
                  >
                    <Image
                      className="w-full"
                      src={feature.image}
                      alt=""
                      sizes="52.75rem"
                    />
                  </div>
                )}
              </Tab.Panel>
            ))}
          </div>
          <div className="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10" />
        </Tab.Panels>
      </>
    </Tab.Group>
  )
}

export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for simplifying everyday business tasks"
      className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32"
    >
      <Container>
        <div className="text-center max-w-3xl mx-auto">
          <h2 className="text-sm font-semibold leading-7 text-blue-600 uppercase tracking-widest">
            From subscriber to supporter
          </h2>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Build your own decentralized media empire
          </h2>
          <p className="mt-4 text-lg leading-8 text-gray-600">
            Paragraph gives you everything you need to turn your passions into a
            sustainable business, with guarantees that you’ll always own the
            relationship with your audience.
          </p>
        </div>

        <FeaturesMobile />
        <FeaturesDesktop />
      </Container>
    </section>
  )
}
