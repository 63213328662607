const stats = [
  { id: 1, name: "Newsletters delivered monthly", value: "1m+" },
  { id: 2, name: "Creators on Paragraph", value: "10,000+" },
  { id: 3, name: "Readers", value: "500k+" },
  { id: 4, name: "Pieces of content collected", value: "250k+" },
]

export default function Stats() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="text-sm font-semibold leading-7 text-blue-600 uppercase tracking-widest">
              Love at first write
            </h2>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Trusted by creators worldwide
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-600">
              From artists to writers to podcasters and beyond, thousands of
              creators are growing {/*earning a living*/}on Paragraph.
            </p>

            <a
              className="mt-4 group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none
              ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300
              "
              href="/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              View trending posts
            </a>
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.id} className="flex flex-col bg-gray-400/5 p-8">
                <dt className="text-sm font-semibold leading-6 text-gray-600">
                  {stat.name}
                </dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
